import React from 'react'

const BlankPage = () => {

  return (
    <div>
      <h1>Blank Page</h1>
    </div>
  )
}

export default BlankPage