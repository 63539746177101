import React, { useEffect, useState } from "react";
import APNavBar from "../Elements/APNavBar";
import APSidebar from "../APSidebar/APSidebar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import BaseApi from "../../api/BaseApi";
import ApiKey from "../../api/ApiKey";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
// import DeleteIcon from "@mui/icons-material/Delete";

import RoleGroup from "./RoleGroup";
import APFooter from "../Elements/APFooter";

const APManageRoles = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get("token");
  const adminID = Cookies.get("adminID");

  const navigate = useNavigate();
  const { slug } = useParams();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + ``,
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        }
      );
      setLoading(false);
      setUserData(response.data.response);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get profile photo data");
    }
  };
  const handleClick = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Update Roles?",
        text: "Do you want to update the roles?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        // setLoading(true);

        const response = await axios.post(
          BaseApi + `/admin/candidates/certificates/${slug}`,
          userData,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        if (response.data.status === 200) {
          Swal.fire({
            title: "Roles updated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
          getData();

          window.scrollTo(0, 0);
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
      }
      // }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not update Roles. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not update photo!");
    }
  };

  //   useEffect(() => {
  //     // Check if tokenKey is not present
  //     if (!tokenKey) {
  //       // Redirect to the home page
  //       navigate("/admin");
  //     } else {
  //       // TokenKey is present, fetch data or perform other actions
  //       getData();
  //       window.scrollTo(0, 0);
  //     }
  //   }, [tokenKey, navigate]);

  const [formData, setFormData] = useState({
    manageEmployer: false,
    add: false,
    edit: false,
    delete: false,
  });

  // Function to handle changes in the "Manage Employers" checkbox
  const handleManageEmployerChange = (e) => {
    const isChecked = e.target.checked;
    setFormData({
      ...formData,
      manageEmployer: isChecked,
      add: isChecked,
      edit: isChecked,
      delete: isChecked,
    });
  };

  const handleRoleGroupChange = (roleGroup, role, isChecked) => {
    // Update the formData state based on the changes
    setFormData((prevData) => ({
      ...prevData,
      [role]: isChecked,
    }));
  };
  

  return (
    <>
      <APNavBar />
      <div className="APBasic">
        <APSidebar />

        {loading ? (
          <>
            <div className="loader-container"></div>
          </>
        ) : (
          <>
            <div className="site-min-height">
              <div className="breadCumb1" role="presentation">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon fontSize="small" />}
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate("/admin/admins/dashboard")}
                  >
                    Dashboard
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate("/admin/admins/manage")}
                  >
                    Subadmin List
                  </Link>

                  <Typography color="text.primary">Manage Roles</Typography>
                </Breadcrumbs>
              </div>
              <h2 className="mt-4">Manage Roles</h2>
              <form className="adminForm">
                <div className="mb-4 mt-5">
                  <div class="h4">Select Roles:</div>
                  <div className="row manageRoleBody">
                    <div className="col-md-3">
                      <RoleGroup
                        title="Manage Employers"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                      <RoleGroup
                        title="Manage Jobs"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                      <RoleGroup
                        title="Manage Blogs"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                       <RoleGroup
                        title="Manage Pages"
                        roles={["Edit"]}
                        onChange={handleRoleGroupChange}
                      />
                    </div>
                    <div className="col-md-3">
                      <RoleGroup
                        title="Manage Designations"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                      
                      <RoleGroup
                        title="Manage Locations"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                      <RoleGroup
                        title="Manage Categories"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                      <RoleGroup
                        title="Manage Email Template List"
                        roles={["Edit"]}
                        onChange={handleRoleGroupChange}
                      />
                    </div>
                    <div className="col-md-3">
                      <RoleGroup
                        title="Manage Jobseekers"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                      
                      
                      <RoleGroup
                        title="Manage Courses"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                      <RoleGroup
                        title="Manage Skills"
                        roles={["Add", "Edit", "Delete"]}
                        onChange={handleRoleGroupChange}
                      />
                    
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary button1"
                    onClick={handleClick}
                  >
                    SAVE
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary button2"
                    onClick={() => navigate("/admin/admins/manage")}
                  >
                    CANCEL
                  </button>
                </div>
              </form>
            </div>
            <APFooter />
          </>
        )}
      </div>
    </>
  );
};

export default APManageRoles;
